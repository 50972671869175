import moment from "moment";
import {format, fromUnixTime, isSameDay} from "date-fns";

export function leadingSlash (str) {
    return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
    return str.endsWith('/') ? str : str + '/'
}

export function removeLeadingSlash (str) {
    return str.replace(/^\/+/, '')
}

export function removeTrailingSlash (str) {
    return str.replace(/\/$/, '');
}

export const wait = timeout => {
    return new Promise(resolve => setTimeout(resolve, timeout))
}

export function getDuration(now, then) {

    const ms = moment(moment.utc(now)).diff(moment(moment.utc(then))) + 1000;
    const duration = moment.duration(ms) ;
    return Math.floor(duration.asHours()) + moment.utc(ms).format(":mm");
}

export function validBookingTimes(date, start, end) {
    return moment(moment(date + ' ' + end,'YYYY-MM-DD HH:mm')).isAfter(moment(date + ' ' + start,'YYYY-MM-DD HH:mm'),'s')
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function getFileExtension(fileName) {
    return fileName.split(".").pop();
}

export function removeDuplicatesFromArray(arr) {

    const result = [];
    const duplicatesIndices = [];

    // Loop through each item in the original array
    arr.forEach((current, index) => {

        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Loop through each other item on array after the current one
        for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {

            const comparison = arr[comparisonIndex];
            const currentKeys = Object.keys(current);
            const comparisonKeys = Object.keys(comparison);

            // Check number of keys in objects
            if (currentKeys.length !== comparisonKeys.length) continue;

            // Check key names
            const currentKeysString = currentKeys.sort().join("").toLowerCase();
            const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
            if (currentKeysString !== comparisonKeysString) continue;

            // Check values
            let valuesEqual = true;
            for (let i = 0; i < currentKeys.length; i++) {
                const key = currentKeys[i];
                if ( current[key] !== comparison[key] ) {
                    valuesEqual = false;
                    break;
                }
            }
            if (valuesEqual) duplicatesIndices.push(comparisonIndex);

        } // end for loop

    }); // end arr.forEach()

    return result;
}

export function toHHMMSS(elapsedTimeInSeconds, includeSeconds=false) {
    var sec_num = parseInt(elapsedTimeInSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return includeSeconds ? `${hours}:${minutes}:${seconds}`:`${hours}:${minutes}`
}

export function formatTimestamp(timestamp, formatToReturn) {
    return format(fromUnixTime(timestamp), formatToReturn.toString())
}

export function checkIsSameDay(date){
    return isSameDay(new Date(), new Date(date))
}
export function formatDate(item,formatString){
    return format(new Date(item),formatString.toString())
}

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
export function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        days.push(formatDate(new Date(date),'dd'));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

export function isAuthorised(userAccountRoles, actionAuthorisedRoles = ['rootAdmin','admin']){
    return userAccountRoles.some( r => actionAuthorisedRoles.includes(r.name) );
}

export function getGroupName(groups,groupId,style) {
    const g = groups.find(g => g._id === groupId)
    if(!g) return ''
    switch (style) {
        case "abbreviation":
            return `${g.abbr}`
        case "firstUse":
            return `${g.name} (${g.abbr})`
        case "name":
            return `${g.name}`
        default:
            return `${g.name} (${g.abbr})`
    }
}